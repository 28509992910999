import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

import s from '../locale/home/s.json';

import styles from './Home.module.scss';
import {Page, Grid, Text, List, Decor} from '../components';
import coffeeBg from '../images/bg/coffee-bg-small.png';

interface Props {
  data?: any;
  t?: any;
}

type State = {};
type ComposedProps = Props;

class Home extends React.PureComponent<ComposedProps, State> {
  render() {
    const t = this.props.t || (() => []);
    return (
      <Page title="CafePho" disableSiteName>
        <Page.Layout>
          {/* ================================================================================================================= */}
          {/* HERO SECTION */}
          {/* ================================================================================================================= */}
          <div className={styles.Hero}>
            <Img
              className={styles.HeroImage}
              fluid={this.props.data.heroImage.childImageSharp.fluid}
            />
            <div className={styles.HeroContentContainer}>
              {/* <a href="https://www.vecteezy.com/free-vector/coffee-plant">Coffee Plant Vectors by Vecteezy</a> */}
              <img className={styles.HeroContentBg} src={coffeeBg} />
              <div className={styles.HeroContent}>
                <Text className={styles.Tagline} size="h3" tag="h6">
                  {t(s.tagline)}
                </Text>
                <Decor.Line />
                <Text className={styles.Headline} size="h1" tag="h2">
                  {t(s.headline)}
                </Text>
              </div>
            </div>
          </div>
          {/* ================================================================================================================= */}
          {/* VIDEO SECTION */}
          {/* ================================================================================================================= */}
          {/* ================================================================================================================= */}
          {/* INSTRUCTIONS SECTION */}
          {/* ================================================================================================================= */}
          <Page.Section padded>
            <Grid>
              <Grid.ScreenWidth>
                <Text tag="h2" size="h3" align="center">
                  {t(s.instructionsTitle)}
                </Text>
                <Decor.Line />
                <div className={styles.InstructionsGrid}>
                  <div className={styles.Instruction}>
                    <div className={styles.InstructionImage}>
                      <Img
                        fluid={
                          this.props.data.stepOneImage.childImageSharp.fluid
                        }
                      />
                      <div className={styles.InstructionTag}>
                        <Text tag="h5" size="small">
                          {t(s.instructionsStepOneTag)}
                        </Text>
                      </div>
                    </div>
                    <div className={styles.InstructionText}>
                      <Text tag="h3">{t(s.instructionsStepOneTitle)}</Text>
                      <Text hyphenated>
                        {t(s.instructionsStepOne)}
                      </Text>
                    </div>
                  </div>
                  <div className={styles.Instruction}>
                    <div className={styles.InstructionImage}>
                      <Img
                        fluid={
                          this.props.data.stepTwoImage.childImageSharp.fluid
                        }
                      />
                      <div className={styles.InstructionTag}>
                        <Text tag="h5" size="small">
                          {t(s.instructionsStepTwoTag)}
                        </Text>
                      </div>
                    </div>
                    <div className={styles.InstructionText}>
                      <Text tag="h3" uppercase>{t(s.instructionsStepTwoTitle)}</Text>
                      <Text hyphenated>{t(s.instructionsStepTwo)}</Text>
                    </div>
                  </div>
                  <div className={styles.Instruction}>
                    <div className={styles.InstructionImage}>
                      <Img
                        fluid={
                          this.props.data.stepThreeImage.childImageSharp.fluid
                        }
                      />
                      <div className={styles.InstructionTag}>
                        <Text tag="h5" size="small">
                          {t(s.instructionsStepThreeTag)}
                        </Text>
                      </div>
                    </div>
                    <div className={styles.InstructionText}>
                      <Text tag="h3" uppercase>{t(s.instructionsStepThreeTitle)}</Text>
                      <Text hyphenated>
                        {t(s.instructionsStepThree)}
                      </Text>
                    </div>
                  </div>
                </div>
              </Grid.ScreenWidth>
            </Grid>
          </Page.Section>
          <Grid>
            <iframe style={{width: "100%", height: "360px"}} src="https://www.youtube.com/embed/-S5IoQHMhkQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Grid>
          {/* ================================================================================================================= */}
          {/* HOT AND COLD SECTION */}
          {/* ================================================================================================================= */}
          <Page.Section>
            <Grid>
              <Grid.ScreenWidth>
                <div className={styles.InfoSection}>
                  <Img
                    className={styles.InfoImage}
                    fluid={this.props.data.hotDrinkImage.childImageSharp.fluid}
                  />
                  <div className={styles.InfoContentContainer}>
                    <div className={styles.InfoContent}>
                      <svg
                        className={styles.InfoContentIcon}
                        width="24"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M7.467 0c1.102.018 5.555 2.549 6.386 8.558.905-.889 1.409-3.664 1.147-4.843 3.952 2.969 6 6.781 6 11.034 0 5.094-3.43 9.251-8.963 9.251-5.728 0-9.037-3.753-9.037-8.276 0-6.26 5.052-7.62 4.467-15.724zm3.262 19.743c-.749.848-.368 1.945.763 2.045 1.035.093 1.759-.812 2.032-1.792.273-.978.09-2.02-.369-2.893-.998 1.515-1.52 1.64-2.426 2.64zm4.42 1.608c2.49-1.146 3.852-3.683 3.852-6.58 0-2.358-.94-4.977-2.5-7.04-.743 2.867-2.924 3.978-4.501 4.269.05-3.219-.318-6.153-2.602-8.438-.296 4.732-4.321 7.63-4.398 12.114-.029 1.511.514 3.203 1.73 4.415.491.489 1.054.871 1.664 1.16-.121-.608-.062-1.254.195-1.848.911-2.106 3.333-2.321 4.202-5.754.952.749 3.275 3.503 2.778 6.358-.082.469-.224.923-.42 1.344z" />
                      </svg>
                      <Text tag="h3" uppercase>{t(s.hotSectionTitle)}</Text>
                      <Decor.Line />
                      <Text size="h5">
                        {t(s.hotSectionDesc)}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={styles.InfoSection}>
                  <Img
                    className={styles.InfoImage}
                    fluid={this.props.data.coldDrinkImage.childImageSharp.fluid}
                  />
                  <div className={styles.InfoContentContainer}>
                    <div className={styles.InfoContent}>
                      <svg
                        className={styles.InfoContentIcon}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M23 17.419l-1.896-1.067 1.862-.499-.35-1.256-3.141.837-1.252-.705 4.037-1.067-.351-1.256-5.314 1.406c-1.409-.794-1.411-2.832 0-3.626l5.314 1.406.351-1.256-4.036-1.066 1.252-.705 3.141.837.35-1.256-1.862-.499 1.896-1.067-.688-1.162-1.896 1.067.488-1.823-1.291-.332-.825 3.072-1.252.705 1.071-3.943-1.291-.332-1.408 5.192c-1.541.868-3.221-.163-3.221-1.813l3.906-3.786-.94-.924-2.966 2.876v-1.41l2.314-2.234-.939-.924-1.375 1.324v-2.133h-1.375v2.135l-1.375-1.324-.94.923 2.314 2.234v1.41l-2.965-2.877-.94.924 3.906 3.786c0 1.669-1.688 2.677-3.221 1.813l-1.408-5.191-1.291.332 1.071 3.943-1.252-.705-.825-3.071-1.291.332.488 1.823-1.896-1.068-.687 1.162 1.896 1.067-1.863.499.35 1.256 3.141-.837 1.251.704-4.037 1.067.351 1.256 5.314-1.406c1.386.78 1.587 2.732 0 3.626l-5.314-1.406-.351 1.256 4.037 1.067-1.252.705-3.141-.837-.35 1.256 1.862.499-1.895 1.066.688 1.162 1.896-1.067-.488 1.823 1.291.332.825-3.072 1.252-.705-1.071 3.943 1.291.332 1.408-5.192c1.525-.859 3.221.138 3.221 1.813l-3.906 3.786.94.924 2.966-2.876v1.41l-2.314 2.234.939.924 1.375-1.324v2.134h1.375v-2.135l1.375 1.324.939-.924-2.314-2.234v-1.41l2.966 2.876.94-.924-3.906-3.786c0-1.694 1.703-2.667 3.221-1.813l1.408 5.192 1.291-.332-1.071-3.943 1.252.705.825 3.072 1.291-.332-.488-1.823 1.896 1.067.687-1.161zm-8.802-4.182l-2.198 1.238-2.198-1.238v-2.475l2.198-1.237 2.198 1.238v2.474z" />
                      </svg>
                      <Text tag="h3" uppercase>
                        {t(s.coldSectionTitle)}
                      </Text>
                      <Decor.Line />
                      <Text size="h5">
                        {t(s.coldSectionDesc)}
                      </Text>
                    </div>
                  </div>
                </div>
              </Grid.ScreenWidth>
            </Grid>
          </Page.Section>
          {/* ================================================================================================================= */}
          {/* PRODUCT SECTION */}
          {/* ================================================================================================================= */}
          <div className={styles.UnrivalledSection}>
              <Img
                className={styles.UnrivalledImageOne}
                fluid={this.props.data.topViewCafeImage.childImageSharp.fluid}
              />
              <div className={styles.UnrivalledContent}>
                  <Text tag="h2" size="h1">
                    {t(s.unrivalledTitle)}
                  </Text>
                  <Text>
                    {t(s.unrivalledDesc)}
                  </Text>
              </div>
          </div>
          {/* ================================================================================================================= */}
          {/* RECIPE SECTION */}
          {/* ================================================================================================================= */}
          <Page.Section>
            <Grid>
              <Grid.ScreenWidth>
                <Text tag="h2" size="h1" align="center" uppercase>
                  {t(s.recipeTitle)}
                </Text>
                <Decor.Line />
                <div className={styles.RecipeGrid}>
                  <div className={styles.RecipeVideo}>
                    <iframe
                      width="100%"
                      height="320"
                      src="https://www.youtube.com/embed/zQ7o6uiMVhM"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  <div className={styles.RecipeContentContainer}>
                    <div className={styles.RecipeContent}>
                      <Text size="h6">
                        {t(s.recipeDesc)}
                      </Text>
                      <List size="small">
                        {t(s.recipeList).map((item, index)=>(<List.Item key={index}>{item}</List.Item>))}
                      </List>
                    </div>
                  </div>
                </div>
              </Grid.ScreenWidth>
            </Grid>
          </Page.Section>
        </Page.Layout>
      </Page>
    );
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: {eq: "optimized/hero-image.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      stepOneImage: file(relativePath: {eq: "optimized/step-one.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      stepTwoImage: file(relativePath: {eq: "optimized/step-two.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      stepThreeImage: file(relativePath: {eq: "optimized/step-three.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      hotDrinkImage: file(relativePath: {eq: "optimized/hot-drink.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      coldDrinkImage: file(relativePath: {eq: "optimized/cold-drink.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      topViewCafeImage: file(
        relativePath: {eq: "optimized/top-view-cafe.jpg"}
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      coffeeBeansImage: file(relativePath: {eq: "optimized/coffee-beans.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Home data={data} t={props.t} />;
};
